import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { rpc } from './api/ether';

// 1. Get projectId
const projectId = 'dcca1f5c9140c19fef0fc17f64a22d53'

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum Mainnet',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io/',
  rpcUrl: rpc
}

// 3. Create modal
const metadata = {
  name: 'Vision Pro',
  description: 'Vision Pro',
  url: 'https://www.freevision.pro', // origin must match your domain & subdomain
  icons: ['https://www.freevision.pro/logo.png']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </div >
  );
}

export default App;
