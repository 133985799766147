import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { Home } from '@/pages'
type CutomFallBackT =
  | boolean
  | React.ReactChild
  | React.ReactFragment
  | React.ReactPortal
  | null
type ChildT = React.LazyExoticComponent<() => JSX.Element> | React.FC
export interface routeProps {
  getHealthcheck?: ()=> Promise<void>
}
// 加载异步组件的loading
const SuspenseWrapper = (Child: ChildT, cutomFallBack?: CutomFallBackT): any => {
  return (
    <React.Suspense fallback={cutomFallBack || null}>
      <Child />
    </React.Suspense>
  )
}
const Routes = () => {
  const RouterList = useRoutes([
    {
      path: '/',
      element: SuspenseWrapper(
        () => <Home/>
      ),
    },
    { path: '*', element: <Navigate to="/" replace /> }
  ])
  return RouterList
}
export default Routes